<template lang="pug">
  div
    v-row(v-if="$isMobileXSmall").fontsize-16.text-center
      v-col(cols="12").pb-1
        span Donation amount to {{ beneficiaryName }}
      v-col(cols="12").pt-1
        span.font-weight-black ₱ {{ amountPaid | commafy }}
    v-row(
      v-else
      justify="space-between"
    ).pa-4.fontsize-20
        span Donation amount to {{ beneficiaryName }}
        span.font-weight-black ₱ {{ amountPaid | commafy }}

    v-row(v-if="$isMobileXSmall").fontsize-16.text-center
      v-col(cols="12").pb-1
        span CARD FEES
      v-col(cols="12").pt-1
        span.font-weight-black ₱ {{ fees | commafy }}
    v-row(
      v-else
      justify="space-between"
    ).pa-4
        span CARD FEES
        span.font-weight-black ₱ {{ fees | commafy }}

    v-row(v-if="$isMobileXSmall").fontsize-16.text-center
      v-col(cols="12").pb-1
        span Support to Mighty Minds
      v-col(cols="12").pt-1
        span.font-weight-black ₱ {{ amountTip | commafy}}
    v-row(
      v-else
      justify="space-between"
    ).pa-4.fontsize-20
        span Support to Mighty Minds
        span.font-weight-black ₱ {{ amountTip | commafy}}

    v-row.px-4.pb-4
      v-divider.border.white

    v-row(v-if="$isMobileXSmall").text-center
      v-col(cols="12").pb-1
        span.fontsize-16 Total amount
      v-col(cols="12").pt-1
        span.fontsize-20.font-weight-black ₱ {{ total | commafy }}
    v-row(
      v-else
      justify="space-between"
    ).pa-4
        span.fontsize-20 Total amount
        span.font-weight-black.fontsize-36 ₱ {{ total | commafy }}
</template>

<script>
export default {
  props: {
    beneficiaryName: {
      type: String
    },
    amountPaid: {
      type: Number
    },
    fees: {
      type: Number
    },
    amountTip: {
      type: Number
    }
  },
  computed: {
    total () {
      return (+this.amountPaid + +this.amountTip + +this.fees).toFixed(2);
    }
  }
};
</script>

<style scoped>
.border {
  border-width: 3px;
}
</style>
