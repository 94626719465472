<template lang="pug">
  div
    v-container(
      fluid
      :class="loading ? 'pa-0' : ''"
    ).pa-0
      v-row(
        v-if="loading"
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'" 
        no-gutters
      ).mt-8.mb-100
        v-col(cols="12")
          div(v-if="$isMediumAndDown")
            v-skeleton-loader(
              type="image"
              max-height="50"
            )
            v-skeleton-loader(
              type="image"
              tile
            ).mt-4
            v-skeleton-loader(
              type="image"
              tile
            ).mb-4
            v-skeleton-loader(type="article").my-4
            v-skeleton-loader(
              v-for="i in 4" 
              :key="i"
              type="text@3"
            )
          div(v-else)
            v-skeleton-loader(
              type="image"
              max-height="50"
            )
            v-row
              v-col(
                cols="12" 
                sm="12" 
                lg="7"
              )
                v-skeleton-loader(
                  type="image"
                  tile
                ).mt-4
                v-skeleton-loader(
                  type="image"
                  tile
                )
                v-skeleton-loader(
                  type="image"
                  tile
                  max-height="130"
                )
                v-skeleton-loader(type="article").my-4
                v-skeleton-loader(type="text@3")
              v-col(
                cols="12" 
                sm="12" 
                lg="5"
              )
                v-skeleton-loader(
                  type="image"
                  tile
                ).mt-4
                v-skeleton-loader(
                  type="image"
                  tile
                  max-height="184"
                ).mb-4
                v-skeleton-loader(
                  type="image"
                  max-height="130"
                ).my-4
                v-skeleton-loader(type="list-item-avatar-three-line")
                v-skeleton-loader(type="card-heading")
                v-row
                  v-col(
                    cols="3"
                    sm="3"
                    md="2"
                    v-for="i in 6"
                    :key="i"
                  )
                    v-skeleton-loader(type="avatar")
      div(v-else)
        //- first panel
        v-row(
          no-gutters
          :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        ).mt-8
          v-col
            h1(:class="$isMediumAndDown ? 'fontsize-24' : 'fontsize-32'").mt-3.text-lg-left.text-sm-center.font-weight-black.accent--text.mb-4  {{ name }} wants to become {{ article }} {{ ambition }}.
        //- second panel
        v-row(
          no-gutters
          align-content-sm="start"
          align-content-lg="space-between"
          :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        ).mb-5
          v-col(
            cols="12"
            sm="12" 
            lg="7"
            align-self="center"
            :class="$isMediumAndDown ? '' : 'pr-3'"
          )
            v-img(
              :src="picURL"
              :alt="alt"
              :aspect-ratio="3/2"
            )
          v-col(
            cols="12"
            sm="12" 
            lg="5" 
            align-self="center"
            :class="$isMediumAndDown ? 'pa-0' : 'pr-0'"
          )
            v-col(v-if="$isMediumAndDown").pa-0
              v-progress-linear(
                :indeterminate="loading"
                height="10"
                :value="fundingRaisedPercent"
              )
              v-row(
                justify="space-between"
              ).mx-0
                span.fontsize-14 ₱{{ fundingRaised | commafy }} raised
                span.fontsize-14 #[strong ₱{{ fundingTogo | commafy}}] to go
              donation-creator(
                :beneficiary="beneficiary"
                @donate="donate"
                :maxAmount="Number(fundingTogo)"
                @amountChange="amountChange"
              ).mt-4
            v-col(v-else).text-center.pr-0
              v-progress-circular(
                v-model="fundingRaisedPercent"
                :rotate="-90"
                :size="350"
                width="20"
                color="primary"
              ).primary--text
                v-col.secondary--text
                  p.fontsize-48.black--text #[strong {{ fundingRaisedPercent }}%]
                  p.mb-4.fontsize-24.font-weight-bold FUNDED
                  br
                  span.fontsize-14 ₱{{ fundingRaised | commafy }} raised &nbsp &nbsp &nbsp &nbsp &nbsp
                  span.fontsize-14 &nbsp &nbsp &nbsp &nbsp &nbsp #[strong ₱{{ fundingTogo | commafy}}] to go
              donation-creator(
                :beneficiary="beneficiary"
                @donate="donate"
                :maxAmount="Number(fundingTogo)"
                @amountChange="amountChange"
              ).mt-4

        //- third panel
        v-row(
          no-gutters
          :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        ).mt-5.mb-12
          v-col(
            cols="12"
            sm="12" 
            lg="7"
            :class="$isMediumAndDown ? '' : 'pr-4'"
          )
            //- story panel
            h2(:class="$isMediumAndDown ? 'text-center' : 'text-left'").fontsize-24.font-weight-bold.accent--text.mb-4 The Story of {{ name }}
            p.text-justify.fontsize-20.secondary--text 
              pre(wrap) {{ story }}
            
            v-divider.my-4

            //- journey panel
            v-row(no-gutters)
              v-col(cols="12")
                h2(:class="$isMediumAndDown ? 'text-center' : 'text-left'").fontsize-24.font-weight-bold.accent--text.mb-4 Scholarship Journey of {{ name }}
                v-timeline(
                  align-top
                  dense
                ).mb-5
                  v-timeline-item(
                    color="primary"
                    fill-dot
                    small
                    v-for="(timelineItem, i) in timelineItems"
                    :key="i"
                  )
                    v-card(
                      :class="color= i%2 === 0 ? 'white' : 'grey lighten-2'"
                    ).pa-4
                      p.mb-0.primary--text.font-weight-black.fontsize-20 {{ timelineItem.title }}
                      p.secondary--text.font-weight-bold.fontsize-14 {{ timelineItem.date }}
                      p.fontsize-18.secondary--text 
                        pre(wrap) {{ timelineItem.body }}
          v-col(
            cols="12"
            sm="12" 
            lg="5"
            :class="$isMediumAndDown ? '' : 'pl-4'"
          )
            //- org panel
            h2(:class="$isMediumAndDown ? 'text-center' : 'text-left'").fontsize-24.font-weight-bold.accent--text.mb-4 Partner Organization
            v-row(no-gutters justify="center").pa-0.ma-n2
              v-col(
                cols="2"
                sm="4"
                md="2"
              ).text-center.mb-3
                v-avatar
                  v-img(
                    :src="organization.picURL"
                    :alt="organization.name"
                  )
              v-col(
                cols="12"
                sm="10"
              )
                p(:class="$isMobileSmallAndDown ? 'fontsize-18 text-center' : 'fontsize-20 text-left'").mx-4.secondary--text {{ organization.name }}
                p(:class="$isMobileSmallAndDown ? 'fontsize-12' : 'fontsize-16'").fontsize-14.mx-4.secondary--text.text-justify
                  pre(wrap) {{ organization.description }}
            
            v-divider.mt-4.mb-7

            //- heroes panel
            h2(:class="$isMediumAndDown ? 'text-center' : 'text-left'").mb-4.fontsize-24.font-weight-bold.accent--text Heroes of {{ name }}
            v-container(grid-list-lg).pa-0
              v-row(
                v-if="benefactorOffset.hasOffset" 
                justify="center"
              ) 
                template(v-for="(v,i) in benefactorsFiltered")
                  v-tooltip(v-if="i !== benefactorsFiltered.length - 1" bottom :key="i")
                    span.fontsize-16 {{ v.nickName }}
                    template(#activator="{ on }")
                      v-col(
                        cols="4" 
                        sm="3" 
                        md="2"
                      ).text-center
                        v-avatar(v-on="on" v-if="v.nickName === 'Anonymous'")
                          v-img(:src="v.picURL")
                        v-avatar(v-else-if="v.nickName === 'Filler'").grey.lighten-2 
                        v-avatar(
                          v-else
                          v-on="on"
                          @click.stop="handleBenefactorBtn(v.uid)"
                        ).pointer
                          v-img(:src="v.picURL")
                  v-tooltip(v-else bottom :key="i")
                    p.mb-0(v-for="(offset, index) in benefactorOffset.offsets").fontsize-16 {{ offset.nickName }}
                    template(#activator="{ on }")
                      v-col(
                        cols="4" 
                        sm="3" 
                        md="2"
                      ).text-center
                        v-avatar(v-on="on").grey.lighten-2
                          span.font-weight-bold.fontsize-24 +{{ benefactorOffset.offsets.length }}
              v-row(
                v-else
                justify="center"
              )
                template(v-for="(v,i) in benefactors")
                  v-tooltip(bottom :key="i")
                    span.fontsize-16 {{ v.nickName }}
                    template(#activator="{ on }")
                      v-col(
                        cols="4" 
                        sm="3" 
                        md="2"
                      ).text-center
                        v-avatar(v-on="on" v-if="v.nickName === 'Anonymous'")
                          v-img(:src="v.picURL")
                        v-avatar(v-else-if="v.nickName === 'Filler'").grey.lighten-2 
                        v-avatar(
                          v-else
                          v-on="on"
                          @click.stop="handleBenefactorBtn(v.uid)"
                        ).pointer
                          v-img(:src="v.picURL")

        //- fourth panel
        v-row(
          no-gutters
          :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        ).grey.lighten-2.py-5
          v-col(cols="12").mx-auto.text-center
            h2.mb-4.fontsize-24.font-weight-bold.accent--text {{ nameWithApostrophe }} Inspiration Box
            h4.font-weight-medium.mb-0.secondary--text.mb-8 Words of motivation from {{ name }}’s heroes.
              br
              | To all {{ nameWithApostrophe }} heroes, your support means a lot to our future {{ ambition }}.
              br
              | From {{ name }} and Mighty Minds, thank you very much!
          v-col(cols="12")
            v-sheet(
              v-if="donationsWithMessage.length === 0"
              align-self="center"
              :class="$isMobileXSmall ? 'pa-4' : 'pa-12'"
            ).text-center
              v-row(justify="center")
                v-col(cols="12")
                  v-avatar
                    img(
                      src="@/assets/mmi-icon-chat.png"
                      alt="Chat icon"
                    )
                  p.mb-0.mt-2.accent--text No message for this scholar yet.
                  p.accent--text Donate to send a message. 
                v-col(
                  cols="12"
                  sm="8"
                  md="5"
                )
                  donation-creator(
                    :beneficiary="beneficiary"
                    @donate="donate"
                    :maxAmount="Number(fundingTogo)"
                  )
            v-simple-table(
              v-else
              fixed-header 
              height="288"
            )
              thead
                tr
                  th.text-left.fontsize-14.secondary--text Heroes Name
                  th.text-left.fontsize-14.secondary--text Message
              tbody
                tr(
                  v-for="(donation,i) in donationsWithMessage" 
                  :key="i"
                )
                  td.width-25p.fontsize-14.accent--text {{ donation.benefactorName }}
                  td.width-75p.fontsize-14.secondary--text {{ donation.message || '' }}

        //- fifth panel
        v-row(
          no-gutters
          :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        ).mt-5
          v-col(cols="12").mx-auto.text-center
            h2(:class="$isMediumAndDown ? 'fontsize-24' : 'fontsize-32'").font-weight-black.accent--text.mb-4 Support another student
            h4.font-weight-medium.secondary--text.fontsize-18.mb-6 Because a hero saves as many as he can.
              br
              | Your donation doesn’t only help this student finish school, it creates a ripple effect in his
              br
              | family and society as he becomes a teacher, a doctor, an engineer or any profession.
            beneficiary-list(
              :number="$isMobileSmall ? 3 : $isMobileXSmall ? 2 : 4" 
              :skip="id || randomId"
            ).mb-100
    //- donation-summary-dialog(
    //-   :dialog="summaryDialog"
    //-   :amountPaid="amountPaid"
    //-   :amountTip="amountTip"
    //-   :fees="fees"
    //-   :beneficiaryName="name"
    //-   @confirm="confirm"
    //-   @cancel="confirm"
    //- )
    donation-success-dialog(
      :dialog="successDialog"
      :amountPaid="amountPaid"
      :amountTip="amountTip"
      :fees="fees"
      :beneficiaryName="name"
      :transactionId="transactionId"
      @close="successDialog = false"
    )
</template>

<script>
import _ from 'lodash';
import BeneficiaryList from '../components/BeneficiaryList';
import DonationCreator from '../components/DonationCreator';
import DonationSuccessDialog from '../components/DonationSuccessDialog';
import DonationSummaryDialog from '../components/DonationSummaryDialog';
import { format } from 'date-fns';

const COL_COUNTS = Object.freeze([ 6, 8, 12 ]);
const ANONYMOUS = Object.freeze(require('@/assets/mmi-profile-anonymous.png'));
const IMAGE_DEFAULT = Object.freeze(require('@/assets/person-placeholder.png'));

export default {
  components: {
    BeneficiaryList,
    DonationCreator,
    DonationSuccessDialog,
    DonationSummaryDialog
  },
  props: {
    id: {
      type: String
    }
  },
  data: () => ({
    loading: false,
    beneficiary: null,
    successDialog: false,
    summaryDialog: false,
    amountPaid: 0,
    amountTip: 0,
    fees: 0,
    transactionId: '',
    randomId: '',
    amount: 0,
    payload: {},
    donations: []
  }),
  watch: {
    async id () {
      window.scrollTo(0, 0);
      await this.loadData();
    }
  },
  async created () {
    await this.loadData();
  },
  computed: {
    picURL () {
      if (this.loading) return IMAGE_DEFAULT;
      const beneficiary = this.beneficiary || {};
      return beneficiary.picURL || IMAGE_DEFAULT;
    },
    name () {
      const beneficiary = this.beneficiary || {};
      const name = beneficiary.name || {};
      return name.firstName || 'Unnamed';
    },
    alt () {
      const beneficiary = this.beneficiary || {};
      const name = beneficiary.name || {};
      return name.firstName ? name.firstName.charAt(1) + name.lastName.charAt(1) : 'Scholar picture';
    },
    nameWithApostrophe () {
      const name = this.name;
      const append = name.charAt(name.length - 1) === 's' ? '’' : '’s';
      return name + append;
    },
    story () {
      const beneficiary = this.beneficiary || {};
      return beneficiary.storyLong || `${this.name}'s bio`;
    },
    ambition () {
      const beneficiary = this.beneficiary || {};
      return beneficiary.ambition || `${this.name}'s ambition`;
    },
    funding () {
      const beneficiary = this.beneficiary || {};
      return beneficiary.activeFundingObject || {};
    },
    fundingTotal () {
      return this.funding.amount || 0;
    },
    fundingRaised () {
      return this.funding.raised || 0;
    },
    fundingRaisedPercent () {
      const fundingRaisePercent = _.round(((Number(this.fundingRaised) + this.amount) / Number(this.fundingTotal)) * 100) || 0;
      return !fundingRaisePercent ? 0 : fundingRaisePercent >= 100 ? 100 : fundingRaisePercent;
    },
    fundingTogo () {
      const fundingTogo = (this.fundingTotal - this.fundingRaised);
      return fundingTogo <= 0 ? 0 : fundingTogo;
    },
    benefactors () {
      const donations = this.donations.map(donation => ({
        ...donation.createdBy,
        picURL: _.get(donation.createdBy, 'picURL') || ANONYMOUS,
        nickName: _.get(donation.createdBy, 'name.firstName') || 'Anonymous'
      }));
      const iterator = _.floor(donations.length / (this.colCountByScreen / 2)) + 1;
      if (iterator > 2) return donations;

      for (let a = 0; (iterator * (this.colCountByScreen / 2)) - donations.length; a++) {
        donations.push({ nickName: 'Filler' });
      }

      return donations;
    },
    benefactorOffset () {
      const benefactors = this.benefactors;

      const offset = benefactors.length > this.colCountByScreen ? benefactors.length - this.colCountByScreen : 0;
      let result = [];
      if (offset !== 0) {
        result.offsets = benefactors.filter((ben, index) => index > this.colCountByScreen - 2).map(b => ({
          nickName: _.get(b, 'name.firstName') || 'Anonymous'
        }));
        result.hasOffset = true;
        return result;
      } else {
        return {
          hasOffset: false
        };
      }
    },
    benefactorsFiltered () {
      return this.benefactors.filter((b, index) => index < this.colCountByScreen);
    },
    colCountByScreen () {
      return this.$isMobileXSmall ? COL_COUNTS[0] : this.$isMobileSmall ? COL_COUNTS[1] : COL_COUNTS[2];
    },
    organization () {
      const beneficiary = this.beneficiary || {};
      const organization = beneficiary.organization || {};
      return {
        ...organization
      };
    },
    timelineItems () {
      const beneficiary = this.beneficiary || {};
      const timelineItems = beneficiary.timeline || [];
      return timelineItems.map(b => ({
        ...b,
        date: format(new Date(b.date), 'MMM d, yyyy')
      }));
    },
    article () {
      const regex = /[AEIOUaeiou]/;
      const beneficiary = this.beneficiary || {};
      const ambition = beneficiary.ambition || 'a/an';
      return ambition ? ambition.charAt(0).match(regex) == null ? 'a' : 'an' : 'a/an';
    },
    donationsWithMessage () {
      if (_.isEmpty(this.donations)) return [];
      return this.donations.filter(donation => !!donation.message)
        .sort((a, b) => b.createdAt - a.createdAt);
    }
  },
  methods: {
    async confirm () {
      try {
        this.$setLoading(true);

        const donation = await this.$store.dispatch('donation/makeDonation', this.payload.posting);
        const funding = await this.$store.dispatch('funding/getFunding', {
          id: this.beneficiary.activeFunding
        });
        const donations = await this.$store.dispatch(
          'donation/getByParams',
          {
            'beneficiary.id': this.beneficiary.id,
            funding: this.beneficiary.activeFunding
          }
        );
        this.donations = donations.map(d => ({
          ...d,
          benefactorName: _.get(d, 'createdBy.name.firstName') || 'Anonymous'
        }));

        if (funding.raised >= funding.amount) {
          this.$store.dispatch(
            'funding/resolve',
            {
              id: funding.id,
              beneficiary: this.beneficiary.id,
              $resolve: true
            }
          );
        }

        this.beneficiary.activeFundingObject = funding;
        this.transactionId = donation.id;
        this.successDialog = true;
      } catch (e) {
        this.successDialog = false;
        this.$enqueueSnack(e);
      } finally {
        this.$setLoading(false);
      }
    },
    // NOTE: not in use anymore within this component
    // async signup () {
    //   try {
    //     this.$enqueueSnack('Signing up');
    //     await this.$store.dispatch(
    //       'auth/signup',
    //       {
    //         firstName: this.payload.account.firstName,
    //         lastName: this.payload.account.lastName,
    //         email: this.payload.account.email,
    //         password: this.payload.account.password
    //       }
    //     );
    //     return true;
    //   } catch (err) {
    //     if (err.message === 'The email address is already in use by another account.') {
    //       try {
    //         this.$enqueueSnack('Email address is already in use. Attempting to login.');
    //         await this.$store.dispatch(
    //           'auth/signin',
    //           {
    //             email: this.payload.account.email,
    //             password: this.payload.account.password
    //           }
    //         );
    //         this.$enqueueSnack('Login success');
    //         return true;
    //       } catch (error) {
    //         if (error.code === 'auth/wrong-password') this.$enqueueSnack(Error('Incorrect password.'));
    //         else this.$enqueueSnack(error);
    //         return false;
    //       }
    //     } else {
    //       this.$enqueueSnack(err);
    //       return false;
    //     }
    //   }
    // },
    donate (payload) {
      this.payload = payload;
      this.amountPaid = Number(payload.posting.baseAmount);
      this.amountTip = Number(payload.posting.tip);
      this.fees = payload.fees;
      this.confirm();
    },
    async loadData () {
      this.amount = 0;
      this.loading = true;
      try {
        if (!this.id) {
          await this.$store.dispatch('beneficiaries/findBeneficiaries', { $limit: 1 });
        }
        const id = this.id || await Promise
          .resolve(_.sampleSize(this.$store.state.beneficiaries.beneficiaries, 1)[0])
          .then(i => i && i.id);
        if (!id) throw new Error(`Could not find a random beneficiary`);
        this.randomId = id;
        this.beneficiary = await this.$store.dispatch('beneficiaries/getBeneficiary', { id });

        const donations = await this.$store.dispatch(
          'donation/getByParams',
          {
            'beneficiary.id': this.beneficiary.id,
            funding: this.beneficiary.activeFunding
          }
        ) || [];
        this.donations = donations.map(d => ({
          ...d,
          benefactorName: _.get(d, 'createdBy.name.firstName') || 'Anonymous'
        }));
      } catch (error) {
        if (error.code === 'beneficiary/not-found') {
          this.$router.push('/notfound');
        } else if (error.toString() === 'TimeoutError: Request timed out') {
          this.$router.push('/notfound');
        }
        this.$enqueueSnack(error);
      } finally {
        this.loading = false;
      }
    },
    scrollToTop () {
      window.scrollTo(0, 0);
    },
    amountChange (payload) {
      this.amount = !payload ? 0 : Number(payload.amount);
    },
    handleBenefactorBtn (uid) {
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click-beneficiary-benefactor-button',
        eventLabel: 'beneficiary-benefactor-button'
      });
      this.$router.push('/benefactor/' + uid);
    }
  },
  mounted () {
    this.scrollToTop();
  }
};
</script>

<style scoped>
.mb-100 {
  margin-bottom: 100px;
}
.mt-100 {
  margin-top: 100px;
}
.height-75 {
  height: 75px;
}
.height-168 {
  height: 168px;
}
.height-50 {
  height: 50px;
}
.height-320 {
  height: 320px;
}
.height-600 {
  height: 600px;
}
.height-408 {
  height: 408px;
}
.height-168 {
  height: 168px;
}
.width-50 {
  width: 50px;
}
.width-75p {
  width: 75%;
}
.width-25p {
  width: 25%;
}
pre {
  font-family: Poppins !important;
}
.pointer:hover {
  cursor: pointer;
}
</style>
