<template lang="pug">
  div
    v-btn(
      color="primary"
      :disabled="!beneficiary || !amount"
      x-large
      depressed
      @click="support"
    ).fontsize-18.text-none Support
    v-dialog(
      v-model="dialogDonate" 
      persistent 
      width="700" 
      scrollable
    )
      donation-form(
        @close="dialogDonate=false"
        :amount="amount"
        :maxAmount="maxAmount"
        :beneficiary="beneficiary"
        @done="done"
      )
    v-dialog(
      v-model="dialogSignin"
      v-if="dialogSignin"
      @click:outside="dialogSignin = false"
      width="500"
    )
      signin(@close="dialogSignin = false")
</template>

<script>
import DonationForm from './DonationForm';
import Signin from './Signin';

export default {
  props: {
    amount: Number,
    maxAmount: {
      type: Number
    },
    beneficiary: {
      type: Object
    }
  },
  components: {
    DonationForm,
    Signin
  },
  data () {
    return {
      dialogSignin: false,
      dialogDonate: false
    };
  },
  methods: {
    support () {
      if (!this.$currentUser) {
        this.dialogSignin = true;
        return;
      }
      this.dialogDonate = true;
    },
    done (data) {
      this.$emit('done', data);
    },
    handleSupportBtn () {
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click-beneficiary-support-button',
        eventLabel: 'beneficiary-support-button'
      });
    }
  }
};
</script>
