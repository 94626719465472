import { commafy } from './format';

// export function generateAmountList (maxAmount = 100) {
//   let list = [];
//   let fives = _.floor(maxAmount / 5);
//   let hundreds = _.floor(maxAmount / 100);

//   for (let i = 0; i < fives; i++) {
//     let amount = (i + 1) * 5;
//     if (amount < 100) {
//       list.push(amount);
//     } else break;
//   }

//   for (let i = 0; i < hundreds; i++) {
//     let amount = (i + 1) * 100;
//     list.push(amount);
//   }

//   return list;
// }

export const generateAmountList = ({ activeFundingObject = {} }, removePayAll) => {
  const remaining = activeFundingObject.amount - activeFundingObject.raised;
  const items = [
    {
      text: `Pay all remaining ₱${commafy(remaining)}`,
      value: remaining
    },
    {
      text: '₱250',
      value: 250
    },
    {
      text: '₱500',
      value: 500
    },
    {
      text: `₱${commafy(1000)}`,
      value: 1000
    },
    {
      text: `₱${commafy(5000)}`,
      value: 5000
    },
    {
      text: `₱${commafy(10000)}`,
      value: 10000
    },
    {
      text: `₱${commafy(20000)}`,
      value: 20000
    }
  ];

  if (removePayAll || +remaining <= 0) {
    items.splice(0, 1);
  }
  return items.filter(item => item.value <= +remaining && +remaining > 0);
};
