<template lang="pug">
  v-dialog(v-model="model" persistent width="600") 
    v-card
      v-card-title.text-center
        v-row(no-gutters)
          v-col(cols="12")
            img(
              width="200"
              src="../assets/mmi-success-logo.png"
              alt="Donation success"
            )
            p.fontsize-36.font-weight-black.black--text.my-4 THANK YOU!
            p.fontsize-20.black--text.word-break Your donation was successfully sent!
            p.fontsize-14.pa-0.ma-0.secondary--text.word-break Check out your transaction amount below:
      v-card-text.card-bg.black--text
        donation-amount-summary(
          :beneficiaryName="beneficiaryName"
          :amountPaid="amountPaid"
          :fees="fees"
          :amountTip="amountTip"
        )
        v-row(
          justify="space-between"
        ).fontsize-12.px-4
          v-col.pt-0
            pre #[small Transaction ID: {{ transactionId }}]
      v-card-text.pt-4.text-center
        v-btn(
          color="primary"
          large
          depressed
          @click.stop="close"
        ).fontsize-16.text-none Helping is my pleasure
        p.mt-4 Do you want to share your skills and services to us?&nbsp
          br(v-if="$isMobileXSmall")
          router-link(
            :to="{name: 'volunteer'}"
            title="Mighty Minds Be A Volunteer"
          ) Be A Volunteer
          |.
        
</template>

<script>
import DonationAmountSummary from './DonationAmountSummary';

export default {
  props: {
    dialog: Boolean,
    amountPaid: Number,
    amountTip: Number,
    fees: Number,
    transactionId: String,
    beneficiaryName: String
  },
  components: {
    DonationAmountSummary
  },
  data () {
    return {
      model: this.dialog
    };
  },
  watch: {
    dialog (val) {
      this.model = val;
    }
  },
  methods: {
    close () {
      this.model = false;
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.card-bg {
  background: #f5f5f5;
}
.word-break {
  word-break: break-word;
}
</style>