<template lang="pug">
  v-row(
    justify="center"
  )
    v-col(
      cols="7"
      sm="6" 
    )
      v-select(
        :items="amountList"
        item-text="text"
        item-value="value"
        label="Amount"
        hide-details
        v-model.number="amount"
        solo
        flat
        no-data-text="Funding Completed"
        :menu-props="{offsetY: true}"
      ).border
    v-col(
      cols="5"
      sm="4" 
    ).text-right
      donation-dialog(
        :amount="initial"
        :maxAmount="maxAmount"
        :beneficiary="beneficiary"
        @done="done"
      )
</template>

<script>
import DonationDialog from './DonationDialog';
import { generateAmountList } from '../utils/amount';

// const MINIMUM_DONATION = 5;

export default {
  props: {
    beneficiary: {
      type: Object
    },
    maxAmount: {
      type: Number
    }
  },
  components: {
    DonationDialog
  },
  // created () {
  //   this.amount = MINIMUM_DONATION;
  // },
  data () {
    return {
      loading: false,
      dialogDonate: false,
      amount: 0,
      initial: 0,
      amountRule: [
        (v) => v <= this.maxAmount || `Max amount is $${this.maxAmount}.`,
        (v) => v >= 5 || 'Minimum donation amount is $5.'
      ]
    };
  },
  methods: {
    async done (data) {
      const payload = {
        posting: {
          paypalOrderId: data.paypalOrderId,
          paypalPayerId: data.paypalPayerId,
          beneficiary: { id: this.beneficiary.id },
          funding: this.beneficiary.activeFunding,
          tip: data.tip || 0, // tip
          amount: data.amount + (data.tip || 0), // amount with fee + tip
          baseAmount: data.baseAmount, // amount without fee
          currency: 'PHP',
          description: data.description,
          metadata: {
            total: data.amount + (data.tip || 0), // amount with fee + tip
            donation: data.baseAmount, // amount without fee
            tip: data.tip || 0, // tip
            fee: parseFloat((data.amount - data.baseAmount).toFixed(2))
          }
        },
        account: {
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          email: data.email
        },
        fees: data.fees
      };
      if (data.message) {
        payload.posting.message = data.message;
      }
      this.amount = null;
      this.$emit('donate', payload);
      this.amountChange();
      this.amount = 0;
    },
    amountChange () {
      this.$emit('amountChange', { amount: this.amount });
    }
  },
  computed: {
    amountList () {
      return generateAmountList(this.beneficiary || {});
    }
  },
  watch: {
    amount (val) {
      this.initial = val;
      this.amountChange();
    }
  }
};
</script>

<style scoped>
.border {
  border-style: solid; 
  border-width: 1px; 
  border-color: #BDBDBD;
}
</style>