<template lang="pug">
  v-dialog(v-model="model" persistent width="600") 
    v-card
      v-card-title.text-center
        v-row(no-gutters)
          v-col(cols="12")
            img(
              width="200"
              src="../assets/mmi-success-logo.png"
              alt="Donation success"
            )
            br
            p.fontsize-14.pa-0.ma-0.secondary--text.word-break Check out your transaction summary below:
      v-card-text.card-bg.black--text
        donation-amount-summary(
          :beneficiaryName="beneficiaryName"
          :amountPaid="amountPaid"
          :fees="fees"
          :amountTip="amountTip"
        )
      v-card-text.pt-2
        small (optional)
        p.black--text Would you like to leave an inspiring message for {{ beneficiaryName }}?
        v-textarea(
          outlined
          auto-grow
          placeholder="Write your message here..."
          hide-details
          rows="1"
          v-model="message"
        )
      v-divider
      v-card-actions
        v-spacer
        v-btn(
          color="error"
          large
          depressed
          text
          @click.stop="cancel"
        ).fontsize-16.text-none Cancel
        v-btn(
          color="primary"
          large
          depressed
          @click.stop="confirm"
        ).fontsize-16.text-none Confirm
        
</template>

<script>
import DonationAmountSummary from './DonationAmountSummary';

export default {
  props: {
    dialog: Boolean,
    amountPaid: Number,
    amountTip: Number,
    fees: Number,
    transactionId: String,
    beneficiaryName: String
  },
  components: {
    DonationAmountSummary
  },
  data () {
    return {
      model: this.dialog,
      message: ''
    };
  },
  methods: {
    cancel () {
      this.$emit('cancel');
      this.close();
    },
    confirm () {
      this.$emit('confirm', this.message.trim());
      this.close();
    },
    close () {
      this.model = false;
      this.message = '';
    }
  },
  watch: {
    dialog (val) {
      this.model = val;
    }
  }
};
</script>

<style scoped>
.card-bg {
  background: #f5f5f5;
}
.word-break {
  word-break: break-word;
}
</style>