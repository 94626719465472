<template lang="pug">
  v-form(
    ref="form"
    v-model="valid"
  )
    v-card
      v-card-title
        v-row(
          justify="center"
          :class="$isMobileSmallAndDown ? 'fontsize-18' : 'fontsize-24'"
          no-gutters
        ).font-weight-black.text-center.black--text
          v-col
            p.ma-0 Your support means a lot
            p.ma-0 to our future {{ beneficiary.ambition.toLowerCase() }}
      v-card-text
        v-row(
          justify="center"
          :class="$isMobileSmallAndDown ? 'mb-4' : ''"
        )
          v-col(
            cols="3"
            sm="2"
            :align-self="$isMobileXSmall ? 'baseline' : 'center'"
          ).text-center
            v-avatar(tile)
              v-img(
                :src="beneficiary.picURL"
                :alt="alt"
              )
          v-col(
            cols="9"
            sm="7"
            align-self="center"
          )
            span.font-weight-black.fontsize-16.black--text Support {{ beneficiary.name.firstName }}'s future with:
            br
            small.fontsize-14 100% of the amount goes to {{ beneficiary.name.firstName }}
          v-col(
            cols="10"
            sm="3"
          )
            v-select(
              :items="amountList"
              item-text="text"
              item-value="value"
              hide-details
              v-model.number="supportAmount"
              outlined
              :menu-props="{offsetY: true}"
            )
        v-row(
          justify="center"
          :class="$isMobileSmallAndDown ? 'mb-4' : ''"
        )
          v-col(
            cols="3"
            sm="2"
          ).text-center
            v-img(
              :src="creditCardImg"
              contain
              alt="Payment"
            )
          v-col(
            cols="9"
            sm="7"
            align-self="center"
          )
            span.font-weight-black.fontsize-16.black--text Credit card fees:
            br
            small 4.4% + ₱15
              | &nbsp;
              a(target="_blank" href="https://www.paypal.com/ph/webapps/mpp/paypal-seller-fees") see PayPal Fees
          v-col(
            cols="10"
            sm="3"
            align-self="center"
            :class="$isMobileSmallAndDown ? 'text-center' : ''"
          )
            span.px-3.fontsize-16 ₱&nbsp
              span.black--text {{ fees }}
        v-row.mb-4
          v-divider
        v-row(justify="center")
          v-col(
            cols="3"
            sm="2"
          ).text-center
            v-avatar(tile)
              v-img(
                :src="helpMMImg"
                contain
                alt="Support us"
              )
          v-col(
            cols="9"
            sm="7"
            align-self="center"
          )
            small.font-weight-black.black--text (Optional)
            p.mb-0.font-weight-black.fontsize-16.black--text Help Mighty Minds
            p We rely on your donations to keep Mighty Minds running.
          v-col(
            cols="10"
            sm="3"
          )
            v-select(
              :items="mmAmountList"
              item-text="text"
              item-value="value"
              placeholder="Select amount"
              hide-details
              :min="100"
              v-model.number="mmAmount"
              outlined
              :menu-props="{offsetY: true}"
              :rules="[v => (v >= 0 || typeof v === 'undefined') || 'Amount cannot be less than 100']"
              clearable
            )
        v-row
          v-col(cols="12")
            v-card-text.pt-2
              small (optional)
              p.black--text Would you like to leave an inspiring message for {{ beneficiary.name.firstName }}?
              v-textarea(
                outlined
                auto-grow
                placeholder="Write your message here..."
                hide-details
                rows="1"
                v-model="message"
              )
        v-row
          v-col(cols="12")
            v-card(color="#f5f5f5" flat)
              v-card-text
                v-row(:class="$isMobileXSmall ? 'fontsize-20' : 'fontsize-24'").pb-2.font-weight-black.secondary--text
                  v-col(
                    cols="12"
                    sm="12"
                  ).text-right
                    span(:class="$isMobileXSmall ? 'fontsize-20' : 'fontsize-24'") Total:&nbsp;
                    span(:class="$isMobileXSmall ? '' : 'pl-5'") ₱ {{ total | commafy }}
                v-row
                  v-col(
                    cols="12"
                    sm="12"
                  )
                    div#paypal-button-container
                //- v-row(v-show="!$currentUser")
                  v-col(
                    cols="12"
                    sm="6"
                  ).py-0
                    v-text-field(
                      outlined
                      label="First Name"
                      v-model="firstName"
                      :rules="!$currentUser ? firstNameRules : []"
                    )
                  v-col(
                    cols="12"
                    sm="6"
                  ).py-0
                    v-text-field(
                      outlined
                      label="Last Name"
                      v-model="lastName"
                      :rules="!$currentUser ? lastNameRules : []"
                    )
                  v-col(
                    cols="12"
                    sm="6"
                  ).py-0
                    v-text-field(
                      outlined
                      label="Email"
                      v-model="email"
                      :rules="!$currentUser ? emailRules : []"
                    )
                  v-col(
                    cols="12"
                    sm="6"
                  ).py-0
                    v-text-field(
                      v-model="password"
                      label="Password"
                      :rules="!this.$currentUser ? passwordRules : []"
                      :type="passwordVisible ? 'text' : 'password'"
                      :append-icon="passwordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                      @click:append="passwordVisible=!passwordVisible"
                      outlined
                    )
                //- v-row
                //-   v-col(
                //-     cols="12"
                //-     sm="12"
                //-   )
                //-     stripe-elements(
                //-       ref="elementsRef"
                //-       :pk="stripePK"
                //-       :amount="parseFloat(total)"
                //-       @token="tokenCreated"
                //-       @loading="loading = $event"
                //-     )
                //-     small Powered by Stripe
      v-divider
      v-card-actions
        v-spacer(v-if="!$isMobileSmallAndDown")
        v-btn(
          text
          color="secondary"
          @click.stop="close"
          large
        ).text-none Cancel
      //-   v-spacer(v-if="$isMobileSmallAndDown")
      //-   v-btn(
      //-     color="primary"
      //-     @click.stop="submit"
      //-     :loading="loading"
      //-     :disabled="!valid"
      //-     large
      //-     depressed
      //-   ).text-none.px-3 Donate
</template>

<script>
import { generateAmountList } from '../utils/amount';
import creditCardImg from '@/assets/mmi-donation-box-credit-card.png';
import helpMMImg from '@/assets/mmi-donation-box-help-mighty-minds.png';
// import { StripeElements } from 'vue-stripe-checkout';

const CARD_PERCENT_FEE = 4.4 / 100;
const CARD_EXTRA_FEE = 15;

export default {
  // components: {
  //   StripeElements
  // },
  props: {
    amount: Number,
    maxAmount: {
      type: Number
    },
    beneficiary: {
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      valid: false,
      mmAmount: 200,
      supportAmount: this.amount,
      paymongoToken: null,
      creditCardImg,
      helpMMImg,
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      firstNameRules: [
        v => !!v || 'First name is required'
      ],
      lastNameRules: [
        v => !!v || 'Last name is required'
      ],
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'Email must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be at least 6 characters long'
      ],
      passwordVisible: false,
      message: ''
    };
  },
  computed: {
    alt () {
      const beneficiary = this.beneficiary || {};
      const name = beneficiary.name || {};
      return name.firstName ? name.firstName.charAt(1) + name.lastName.charAt(1) : 'Scholar picture';
    },
    fees () {
      const stripeFeePercent = this.amountToPay * CARD_PERCENT_FEE;
      return (stripeFeePercent + CARD_EXTRA_FEE).toFixed(2);
    },
    amountToPay () {
      const amount = ((this.supportAmount + CARD_EXTRA_FEE) / (1 - CARD_PERCENT_FEE));
      return parseFloat(amount.toFixed(2));
    },
    total () {
      return (this.amountToPay + (this.mmAmount || 0)).toFixed(2);
    },
    amountList () {
      return generateAmountList(this.beneficiary);
    },
    mmAmountList () {
      return generateAmountList(this.beneficiary, true);
    }
  },
  methods: {
    async submit ({ orderId, payerId }) {
      this.donationPayload = {
        paypalOrderId: orderId,
        paypalPayerId: payerId,
        description: `₱${this.supportAmount} Donation for ${this.beneficiary.id} - ${this.beneficiary.name.lastName}, ${this.beneficiary.name.firstName} ${this.beneficiary.name.middleName || ''}`,
        tip: this.mmAmount, // 200
        amount: this.amountToPay, // 200 + fee
        baseAmount: this.supportAmount, // 200
        fees: Number(this.fees),
        firstName: this.firstName,
        lastName: this.lastName,
        password: this.password,
        email: this.email
      };
      if (this.message) this.donationPayload.message = this.message;
      this.$emit('done', this.donationPayload);
      this.close();
    },
    close () {
      this.$refs.form.resetValidation();
      this.$emit('close');
    },
    mountPaypalButton () {
      document.getElementById('paypal-button-container').innerHTML = '';
      paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: `₱${this.supportAmount} Donation for ${this.beneficiary.id} - ${this.beneficiary.name.lastName}, ${this.beneficiary.name.firstName} ${this.beneficiary.name.middleName || ''} - ${new Date().toISOString()}`,
                soft_descriptor: 'MIGHTY MINDS ORG',
                amount: {
                  value: this.total,
                  currency_code: 'PHP'
                }
              }
            ]
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            const orderId = data.orderID;
            const payerId = data.payerID;
            this.submit({ orderId, payerId });
          });
        }
      }).render('#paypal-button-container');
    }
  },
  watch: {
    amount (val) {
      this.supportAmount = val;
    },
    total () {
      this.mountPaypalButton();
    }
  },
  mounted () {
    this.mountPaypalButton();
  }
};
</script>

<style scoped>
.border {
  border-style: solid; 
  border-width: 1px; 
  border-color: #BDBDBD;
}
</style>